import { Routes, Route } from 'react-router-dom'
import OverviewPage from './Overview'
import AccountsPage from './Accounts/Accounts'
import NotFoundPage from './NotFound'
import OpenADRPage from './OpenADR/OpenADR'
import ControlPage from './Control/Control'
import UsersPage from './Users/Index'
import UtilityProgramsPage from './UtilityPrograms/UtilityPrograms'
import ReportsPage from './Report/Report'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect } from 'react'

/**
 * Authenticated routes for administration.
 */
const Authenticated = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)
  const getSites = useStoreActions((actions) => actions.site.getRecords)
  useEffect(() => {
    getSites()
  }, [])

  return (
    <Routes>
      <Route path="/" element={<OverviewPage />} />
      <Route path="/accounts/*" element={<AccountsPage />} />
      {currentPartner?.value === 'edo' && <Route path="/openadr/*" element={<OpenADRPage />} />}
      <Route path="/event" element={<ControlPage />} />
      <Route path="/users/*" element={<UsersPage />} />
      <Route path="/utility-programs" element={<UtilityProgramsPage />} />
      <Route path="/report/*" element={<ReportsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default Authenticated
