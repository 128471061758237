/**
 * It's a button.
 * @param {Object} params
 * @param {string} params.label - Label of the button, it shows in the middle
 * @param {boolean} params.disabled - It disables the button, ok?
 */
function Button({ icon, label, disabled, secondary, solid, ...restProps }) {
  return (
    <div
      className={'button' + (secondary ? ' secondary' : '') + (disabled ? ' disabled' : '') + (solid ? ' solid' : '')}
      {...restProps}
    >
      {icon} {label}
    </div>
  )
}

export default Button
