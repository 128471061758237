import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect, useMemo, useCallback, useState } from 'react'
import Table from '../../Components/Table'
import SelectColumnFilter from '../../Components/SelectColumnFilter'
import filterContains from '../../Utilities/FilterContains'
import Pending from '../../Components/Service/Pending'
import CreateButton from '../../Components/Service/CreateButton'
import { Portal } from 'react-portal'

const Service = (props) => {
  const [pendingForm, setPendingForm] = useState(null)
  const [selected, setSelected] = useState([])

  const certificates = useStoreState((state) => state.service.records)
  const certificatesLoading = useStoreState((state) => state.service.recordsLoading)
  const partner = useStoreState((state) => state.partner.selected)

  const getCertificates = useStoreActions((actions) => actions.service.getRecords)

  useEffect(() => {
    getCertificates()
  }, [partner])

  /**
   * Handler for selected row updates to be passed to table
   * @param {Object[]} selection - Array of selected rows
   */
  const handleSelectionChange = useCallback(
    (selection) => {
      setSelected(selection)
    },
    [setSelected]
  )

  // -- TABLE SETTINGS --
  // all valid column options for certificates
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayName',
        filter: 'fuzzyText',
      },
      {
        Header: 'Created By',
        accessor: 'creator',
        Filter: SelectColumnFilter,
        filter: filterContains,
      },
      {
        Header: 'Permission Count',
        accessor: (row) => row.permissions.reduce((totalCnt, x) => totalCnt + x.values.length, 0),
        disableFilters: true,
      },
      {
        Header: 'Status',
        Cell: ({ value }) => (
          <div>
            <FontAwesomeIcon
              icon={value === 'Valid' ? faCircleCheck : faCircleXmark}
              style={{ color: value === 'Valid' ? 'green' : 'red', marginRight: '8px' }}
            />
            {value}
          </div>
        ),
        accessor: (row) => (row.expires >= new Date() ? 'Valid' : 'Expired'),
        Filter: SelectColumnFilter,
        filter: filterContains,
      },
      {
        Header: 'Expires',
        Cell: ({ value }) => value.toLocaleString(),
        accessor: 'expires',
        disableFilters: true,
      },
      {
        Header: 'Last Issued',
        Cell: ({ value }) => value.toLocaleString(),
        accessor: 'lastIssued',
        disableFilters: true,
      },
    ],
    []
  )

  return (
    <div className="App-submodule">
      <div className="table">
        <Portal node={document && document.getElementById('buttons-portal-target')}>
          <div className="createNewButton" style={{ marginLeft: 'auto' }}>
            <CreateButton refreshCertificates={() => getCertificates()} />
          </div>
        </Portal>
        {certificates && (
          <Table
            loading={certificatesLoading}
            singleSelect
            columns={tableColumns}
            data={certificates}
            initialPageSize={15}
            onSelectionChange={handleSelectionChange}
          />
        )}
        <Pending {...props} selectedRows={selected} setPendingForm={setPendingForm} pendingForm={pendingForm} />
      </div>
    </div>
  )
}

export default Service
