import '@src/App.scss'

const ButtonNoFill = ({ icon, label, onClick, ...restProps }) => {
  return (
    <div className="button no-fill" onClick={onClick} {...restProps}>
      {icon} {label}
    </div>
  )
}

export default ButtonNoFill
