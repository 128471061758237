import { createStore, persist } from 'easy-peasy'
import Auth from './Services/Auth'
import Partner from './Services/Partner'
import Service from './Services/Service'
import OpenADR from './Services/OpenADR'
import General from './Services/General'
import OpenADRQueue from './Services/OpenADRQueue'
import Site from './Services/Site'
import Report from './Services/Report'

// store model with certain fields persisted using the 'allow' key
// save table settings (column hiding, order) in local storage since that's a pain to setup every time
const storeModel = {
  auth: Auth,
  partner: persist(Partner, { allow: ['selected'] }),
  service: Service,
  openadr: OpenADR,
  general: General,
  openadrqueue: OpenADRQueue,
  site: Site,
  report: Report,
}

// create a store for global state
// name is used to key local and session storage references
const Store = createStore(storeModel, { name: 'AdminStore' })

export default Store
