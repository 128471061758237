import { apiClient } from '../API'

/**
 * Delete a user.
 */
export const deleteUser = (username) => {
  return apiClient.delete(`/auth/user/${username}`)
}

/**
 * Create a user.
 */
export const postUser = (payload) => {
  return apiClient.post(`/auth/user/`, payload)
}

/**
 * Update a user.
 */
export const patchUser = (username, payload) => {
  // remove any undefined properties
  Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key])

  return apiClient.patch(`/auth/user/${username}`, payload)
}
