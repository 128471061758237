import { action, thunk } from 'easy-peasy'
import { postUser, patchUser } from '@api/Services/User'
import { apiErrorHandler } from '@utilities/Error'
import { toast } from 'react-toastify'

const Auth = {
  user: null,
  actionSuccessful: false,

  openCreateUserLoader: action((state) => {
    state.createUserLoading = true
  }),

  closeCreateUserLoader: action((state) => {
    state.createUserLoading = false
  }),

  openPatchUserLoader: action((state) => {
    state.patchUserLoading = true
  }),

  closePatchUserLoader: action((state) => {
    state.patchUserLoading = false
  }),

  setActionSuccessful: action((state, payload) => {
    state.actionSuccessful = payload
  }),

  setUser: action((state, payload) => {
    state.user = payload
  }),

  updateUser: thunk((actions, payload, { getState }) => {
    let user = payload.username !== '' ? payload.username : payload.idTokenClaims.signInName

    if (user !== getState().user) {
      actions.setUser(user)
    }
  }),

  createUser: thunk(async (actions, payload) => {
    actions.openCreateUserLoader()

    try {
      await postUser(payload)
      toast.success(`User created successfully!`)
      actions.setActionSuccessful(true)
    } catch (err) {
      apiErrorHandler(err)
    }

    actions.closeCreateUserLoader()
  }),

  patchUser: thunk(async (actions, { username, payload }) => {
    actions.openPatchUserLoader()

    try {
      await patchUser(username, payload)
      toast.success(`User updated successfully!`)
      actions.setActionSuccessful(true)
    } catch (err) {
      apiErrorHandler(err)
    }

    actions.closePatchUserLoader()
  }),
}

export default Auth
