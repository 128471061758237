import { useStoreState } from 'easy-peasy'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import EventPage from './Event'

const ControlLayout = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)
  return (
    <div className="App-module">
      <h3 style={{ textAlign: 'left' }}>
        {currentPartner?.label} / <Link to="..">Control</Link>
      </h3>
      <Outlet />
    </div>
  )
}

/**
 * Control routes.
 */
const Control = () => {
  return (
    <Routes>
      <Route path="/" element={<ControlLayout />}>
        <Route path="/" element={<EventPage />} />
      </Route>
    </Routes>
  )
}

export default Control
