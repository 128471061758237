import React, { useState } from 'react'
import { Routes, Route, Outlet, Link, useNavigate } from 'react-router-dom'
import TabSwitchSelector from '../../Components/TabSwitchSelector'
import ServicePage from './Service'
import UsersPage from './Users'
import './Index.scss'
import UserManagement from './UserManagement'

const ChildrenLayout = () => {
  const [selectedTab, setSelectedTab] = useState('Users')
  const navigate = useNavigate()

  const handleTabSelect = (tab) => {
    setSelectedTab(tab)
    if (tab === 'Users') {
      navigate('')
    } else if (tab === 'Service Accounts') {
      navigate('service')
    }
  }

  return (
    <div className="App-module">
      <div className="heading-wrapper">
        <Link className={'custom-heading'} to="..">
          {selectedTab}
        </Link>
        <div id="buttons-portal-target"></div>
      </div>
      <TabSwitchSelector tabs={['Users', 'Service Accounts']} onSelect={handleTabSelect} />
      <Outlet />
    </div>
  )
}

/**
 * User routes.
 */
const Users = () => {
  // Test data for editing user, until user table is created
  const testEditUserData = {
    username: 'testuser@edoenergy.com',
    firstName: 'Test',
    lastName: 'User',
    phoneNumber: '+11234567890',
  }

  return (
    <Routes>
      <Route path="/" element={<ChildrenLayout />}>
        <Route path="/" element={<UsersPage />} />
        <Route path="/service" element={<ServicePage />} />
      </Route>
      <Route path="/add" element={<UserManagement pageName="Add User" action={'add'} />} />
      <Route path="/edit" element={<UserManagement pageName="Edit User" action={'edit'} user={testEditUserData} />} />
    </Routes>
  )
}

export default Users
