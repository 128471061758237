/**
 * Return structured response when InputField component changed
 * @param {Event} event
 * @returns {{ name: string, value: string}}
 */
const getNameValueFromInput = (event) => {
  const target = event.target

  const value =
    target.type === 'checkbox'
      ? target.checked
      : target.type === 'number' && target.value !== ''
      ? Number(target.value)
      : target.value
  const name = target.name

  return { name, value }
}

export default { getNameValueFromInput }
