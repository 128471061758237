export const Display = Object.freeze({
  VTN: 1,
  VEN: 2,
})

// valid view options for the Switch Selector
export const options = [
  {
    id: Display.VTN,
    name: 'Virtual Top Nodes',
  },
  {
    id: Display.VEN,
    name: 'Virtual End Nodes',
  },
]
