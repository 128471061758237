import './App.scss'
import Root from './Routes/Root'
import * as Sentry from '@sentry/react'

import { BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'

const App = ({ instance }) => {
  return (
    <Sentry.ErrorBoundary fallback={<h1>Something went wrong. Please reload the page.</h1>} showDialog>
      <BrowserRouter>
        <MsalProvider instance={instance}>
          <Root />
        </MsalProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  )
}

export default App
