import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loader(props) {
  const relativeStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#f4f4f4',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const style = { width: '100%', height: '200px', ...(props.isRelative && { ...relativeStyle }) }

  return (
    <div className="center" style={style}>
      <div>
        <FontAwesomeIcon pulse style={{ marginRight: '8px' }} icon={faSpinner} />
        Loading
      </div>
    </div>
  )
}

export default Loader
