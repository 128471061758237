import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import CreateModal from './CreateModal'

// default service certificate model
const defaultData = {
  displayName: '',
  description: '',
  permissions: [
    {
      resource: 'service',
      values: [],
    },
  ],
}

function CreateButton({ refreshCertificates }) {
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowEditModal(!showEditModal)
  }, [setShowEditModal, showEditModal])

  return (
    <>
      <div onClick={toggleModal} className="create">
        <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '4px' }} />
        Create New
      </div>
      <CreateModal
        refreshCertificates={refreshCertificates}
        show={showEditModal}
        onToggle={toggleModal}
        defaultModel={defaultData}
      />
    </>
  )
}

export default CreateButton
