import { apiClient } from '../API'

/**
 * @typedef {object} EventRun
 * @property {number} id Internal identifier
 * @property {number} eventId Internal event identifier
 * @property {string} eventName Event name
 * @property {string} eventDescription Event description
 * @property {string} initiator Event initiator
 * @property {string} startDate Event start
 * @property {string} endDate Event end
 * @property {number[]} equipmentIds Involved equipment identifiers
 */

/**
 * @typedef {object} EventRunWrite
 * @property {number} id Internal event write identifier
 * @property {number} eventRunDeviceId Internal event device run identifier
 * @property {string} startTime
 * @property {string} endTime
 * @property {number} pointId
 * @property {string} operationId
 * @property {number} operationValue
 * @property {object} operationConfig
 */

/**
 * Get event runs for a given site
 * @param {number} siteId
 * @returns {Promise<EventRun[]>}
 */
export function getEventRuns(siteId) {
  let config = {
    params: {
      siteId,
    },
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/control/event`, config)
}

/**
 * Get monitor points based on equipment and event
 * @param {number} equipmentId
 * @param {number} eventId
 * @returns {Promise<Site[]>}
 */
export function getMonitorPoints(equipmentId, eventId) {
  return apiClient.get(`/control/event/${eventId}/equipment/${equipmentId}/monitor/point`)
}

/**
 * Get event writes.
 * @param {string} runId Internal run identifier.
 * @returns {Promise<EventRunWrite[]>}
 */
export const getEventWrites = (runId) => {
  return apiClient.get(`/control/event/run/${runId}/write`)
}
